<template>
    <div
        class="facility-list-item"
    >
        <div class="facility-list-item__icon">
            <slot name="icon" />
        </div>
        <div class="facility-list-item__title">
            <slot name="header" />
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="less" src="./FacilityListItem.less"></style>
