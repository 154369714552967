<template>
    <BaseSection
        class="section--facility-list section--header"
        width="content"
    >
        <FacilityListItem
            v-for="(item, i) in listItems"
            :key="`facility-list-item-${i}`"
        >
            <template v-slot:icon>
                <BaseIcon :icon="item.icon" />
            </template>
            <template v-slot:header>
                {{ item.title }}
            </template>
        </FacilityListItem>
    </BaseSection>
</template>

<script>
export default {
    props: {
        content: {
            type: Object,
            required: true,
            default: () => {
                return {};
            },
        },
    },
    computed: {
        listItems() {
            return this?.content?.listItems ?? [];
        },
    },
};
</script>

<style lang="less">
</style>
